@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Roboto", sans-serif;
    color: #27272a;
  }

  input:invalid[focused="true"] ~ span {
    display: block;
  }

  input:invalid[focused="true"] {
    border: 1px solid red;
  }
}
