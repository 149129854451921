.notif{
    z-index : 1;
}
.bell{
    position: relative;
}

.icon{
    /* content: ""; */
    position: absolute;
    top:0;
    right :5%;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: red;
}